<template>
  <div class="Shipping">
    <div class="box-card">
      <el-row style="margin-bottom: 16px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item>Shipping</el-breadcrumb-item>
        </el-breadcrumb>
      </el-row>
      <el-row>
        <el-form ref="searchForm" :model="searchForm" size="small" :label-position="'left'">
          <div style="display: flex" class="vg_mb_8">
            <div class="vg_mr_8">
              <el-form-item label="ETD" :label-width="'40px'" prop="etd">
                <el-date-picker
                  v-model="searchForm.etd"
                  type="daterange"
                  align="right"
                  unlink-panels
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="start date"
                  end-placeholder="end date"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="vg_mr_8">
              <el-form-item label="PO#" :label-width="'40px'" prop="po">
                <el-input v-model="searchForm.po" clearable placeholder="Please enter"></el-input>
              </el-form-item>
            </div>
            <div class="vg_mr_8">
              <el-form-item label="Item#" :label-width="'50px'" prop="item">
                <el-input v-model="searchForm.item" clearable placeholder="Please enter"></el-input>
              </el-form-item>
            </div>
            <div class="vg_mr_8">
              <el-form-item label="Description" :label-width="'80px'" prop="description">
                <el-input v-model="searchForm.description" clearable placeholder="Please enter"></el-input>
              </el-form-item>
            </div>
            <div style="display: flex; align-items: baseline">
              <el-button size="mini" type="primary" @click="search()">Search</el-button>
              <el-button size="mini" type="info" @click="resetSearchForm()">Reset</el-button>
              <el-button size="mini" type="success" @click="exportExcel()">Export to Excel</el-button>
            </div>
          </div>
          <el-input v-model="localSearchInput" size="small" clearable placeholder="Keyword filter table current page data..."></el-input>
        </el-form>
      </el-row>
      <el-row v-loading="loading">
        <el-col :span="24">
          <el-table
            :height="currentInnerHeight"
            ref="table"
            :data="computedTableData"
            border
            :cell-class-name="'cellClassName'"
            class="mtrlsTable"
            :show-summary="true"
            :summary-method="getAllSummaries"
            style="width: 100%"
          >
            <el-table-column align="center" type="index" width="60" />
            <el-table-column align="left" label="PO#" prop="po" min-width="120" />
            <el-table-column align="left" label="Invoice#" prop="invoice" min-width="120" />
            <el-table-column align="left" label="Port" prop="port" min-width="120" />
            <el-table-column align="left" label="SO#" prop="so" min-width="120" />
            <el-table-column align="left" label="CRD" prop="crd" min-width="120" />
            <el-table-column align="left" label="Booking" prop="booking" min-width="120" />
            <el-table-column align="left" label="ETD" prop="etd" min-width="120" />
            <el-table-column align="left" label="Item#" prop="item" min-width="120" />
            <el-table-column align="left" label="Description" prop="description" min-width="180" />
            <el-table-column align="left" label="Quantity" prop="quantity" min-width="120" />
            <el-table-column align="left" label="Unit" prop="unit" min-width="120" />
            <el-table-column align="left" label="Carton Quantity" prop="cartonQuantity" min-width="120" />
            <el-table-column align="left" label="PCS/CTN" prop="pcsCtn" min-width="120" />
            <el-table-column align="left" label="Width(cm)" prop="width" min-width="120" />
            <el-table-column align="left" label="Length(cm)" prop="length" min-width="120" />
            <el-table-column align="left" label="Height(cm)" prop="height" min-width="120" />
            <el-table-column align="left" label="Carton Volume(CBM)" prop="cartonVolume" min-width="180" />
            <el-table-column align="left" label="Total Volume(CBM)" prop="totalVolume" min-width="120" />
            <el-table-column align="left" label="Gross Weight(KGS)" prop="grossWeight" min-width="120" />
            <el-table-column align="left" label="Net Weight(KGS)" prop="netWeight" min-width="120" />
          </el-table>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px; text-align: right">
        <el-pagination
          @current-change="changePageSearch"
          @size-change="changeSizeSearch"
          :current-page="currentPage"
          :page-sizes="[100, 200, 500, 1000, 2000]"
          :page-size="searchForm.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalItem"
        />
      </el-row>
    </div>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import { dyjAPI } from '@api/modules/dyj';
import { get } from '@api/request';
import { downloadFile } from '@assets/pubjs/common';

export default {
  name: 'Shipping',
  components: {},
  data() {
    return {
      searchForm: {
        page_no: 1,
        page_size: 100,
        etd: '',
        po: '',
        item: '',
        description: ''
      },
      tableData: [],
      totalItem: 0,
      currentPage: 1,
      loading: false,
      localSearchInput: '',
      currentInnerHeight: null,
      pickerOptions: {
        shortcuts: [
          {
            text: 'last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'last three months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      }
    };
  },
  computed: {
    computedTableData() {
      return this.tableData.filter(
        data => !this.localSearchInput || Object.values(data).some(value => String(value).toLowerCase().includes(this.localSearchInput.toLowerCase()))
      );
    }
  },
  created() {
    this.initData();
  },
  mounted() {
    let { y } = this.$refs.table.$el.getBoundingClientRect();
    this.currentInnerHeight = window.innerHeight - y - 60 + 'px';
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout();
    });
  },
  methods: {
    initData() {
      this.getData();
    },
    // 获取所有的物料信息
    getData() {
      this.loading = true;
      let searchForm = JSON.parse(JSON.stringify(this.searchForm));
      searchForm.page_no = this.currentPage;
      if (searchForm.etd && searchForm.etd.length === 2) {
        searchForm.start_time = searchForm.etd[0];
        searchForm.end_time = searchForm.etd[1];
      }
      searchForm.etd = null;
      get(dyjAPI.getShipping, searchForm)
        .then(({ data }) => {
          if (data.code === 0) {
            this.tableData = data.data.list;
            this.totalItem = data.data.total;
          } else {
            this.$message.error(data.msg);
          }
          this.loading = false;
        })
        .catch(err => console.info(err));
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getData();
    },
    changeSizeSearch(val) {
      this.searchForm.page_size = val;
      this.changePageSearch(1);
    },
    // 搜索按钮
    search() {
      this.currentPage = 1;
      this.changePageSearch(1);
    },
    // 重置按钮
    resetSearchForm() {
      this.$refs.searchForm.resetFields();
      this.changePageSearch(1);
    },
    exportExcel() {
      get(dyjAPI.exportExcelShipping, this.searchForm)
        .then(({ data }) => {
          if (data.code === 0) {
            downloadFile({ fileUrl: data.data.url, fileName: data.data.fileName });
          } else {
            this.$message.error(data.msg);
          }
          this.loading = false;
        })
        .catch(err => console.info(err));
    },
    getAllSummaries({ columns, data }) {
      return columns.map((column, index) => {
        if (index === 0) return 'Total';
        if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 13, 14, 15, 16, 17].includes(index)) return '';
        const values = data.map(item => Number(item[column.property] || 0));
        const sum = values.reduce((x, item) => x.plus(item), new BigNumber(0)).toNumber();
        return sum || '';
      });
    }
  }
};
</script>
<style scoped>
::v-deep.el-table th > .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
::v-deep.el-table td > .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
<style>
.Shipping .box-card {
  height: 100%;
  margin: 20px 40px 0 230px;
  padding-bottom: 10px;
}
.Shipping .mtrlsTable {
  margin-top: 20px;
}
.cellClassName {
  padding: 0 !important;
}
</style>
